'use strict';

import React, {useState}      from 'react';
import PropTypes              from 'prop-types';
import {BiLinkExternal}       from 'react-icons/bi';
import {AiFillQuestionCircle} from 'react-icons/ai';
import { GrClose }            from 'react-icons/gr'
import {useTranslation}       from 'react-i18next';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const InfoGuide = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const {t} = useTranslation();

    const link =
        // eslint-disable-next-line
        'https://mittelschulen.tg.ch/public/upload/assets/161907/240625_AMH_Broschuere_Wegweiser.pdf?fp=1';

    return (
        <>
            <div className={'info-guide'}>
                <button
                    type={'button'}
                    className={'settings-btn'}
                    onClick={() => setModalOpen(true)}
                >
                    <AiFillQuestionCircle className={'icon-info'}/>
                    Info
                </button>
            </div>
            <div className={'info-guide-big'}>
                <div
                    type={'button'}
                    className={'settings-btn'}
                >
                    <AiFillQuestionCircle className={'icon-info'}/>
                    {t('headerWegweiserTitle')}
                    <a href={link} target={'new'}>{t('headerWegweiserExternalLinkMessage')}</a>
                    <BiLinkExternal/>
                </div>
            </div>

            {
                modalOpen &&
                <div className={'info-modal'}>
                    <GrClose className={'close-modal'} onClick={() => setModalOpen(false)}/>
                    <div className={'modal-content'}>
                        <p>{t('headerWegweiserTitle')}</p>
                        <a href={link} target={'new'}>{t('headerWegweiserExternalLinkMessage')}</a>
                        <BiLinkExternal/>
                    </div>
                </div>
            }

            {
                modalOpen &&
                <div id='modal-backdrop'></div>
            }
        </>
    );
};

InfoGuide.propTypes = {
    authService: PropTypes.any
};
