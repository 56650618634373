'use strict';

import React                                   from 'react';
import PropTypes                               from 'prop-types';
import {useActor}                              from '@xstate/react';
import { useFormik }                           from 'formik';
import { FormGuide }                           from '../formGuide';

import {
    string,
    object
} from 'yup';
import {useTranslation} from 'react-i18next';

export const SchoolGoalForm = ({schoolGoalMachine}) => {
    const [state, send] = useActor(schoolGoalMachine);
    const {context} = state;
    const {
        enabledModules,
        enteredData = {},
        data = [],
        texts: formText,
        userName
    } = context;

    const {error: errorObject = {}} = context;
    const {error = {}} = errorObject.data;
    const {details: errorDetails = {}} = error;
    const {codes = {}} = errorDetails;
    const {
        error: errorCodes = []
    } = codes;

    const {t} = useTranslation();

    const getOriginSchoolOptions = () => {
        return data.map(({id, name}) => {
            return <option key={id} value={id} data-name={name}>{name}</option>;
        })
    };

    const getTargetSchoolTypeData = () => {
        const {originSchool} = enteredData;
        const result = data
            .find(originSchoolEntry => {
                return originSchoolEntry.id === parseInt(originSchool.id);
            });
        return result !== undefined ? result.targetSchoolTypes : [];
    };

    const getTargetSchoolCityData = () => {
        const {targetSchoolType} = enteredData;
        const result = getTargetSchoolTypeData()
            .find(targetSchoolTypeEntry => {
                return targetSchoolTypeEntry.id === parseInt(targetSchoolType.id);
            });


        return result !== undefined ? result.cities : [];
    };

    const getSpecificationData = () => {
        const {targetSchoolCity} = enteredData;
        const result = getTargetSchoolCityData()
            .find(targetSchoolCityEntry => {
                return targetSchoolCityEntry.id === parseInt(targetSchoolCity.id);
            });

        return result !== undefined ? result.specifications : [];
    };

    const getTalentData = () => {
        const {targetSchoolCity} = enteredData;
        const result = getTargetSchoolCityData()
            .find(targetSchoolCityEntry => {
                return targetSchoolCityEntry.id === parseInt(targetSchoolCity.id);
            });

        return result !== undefined ? result.talents ? result.talents : [] : [];
    };

    const getTargetSchoolTypeOptions = () => {
        return getTargetSchoolTypeData()
            .map(({id, name}) => {
                return <option key={id} value={id} data-name={name}>{name}</option>
            });
    };

    const getTargetSchoolCityOptions = () => {
        return getTargetSchoolCityData()
            .map(({id, name, targetSchoolId}) => {
                return <option key={id} value={id} data-name={name} data-target-school-id={targetSchoolId}>
                    {name}
                </option>
            });
    };

    const getSpecificationOptions = () => {
        return getSpecificationData()
            .map(({id, name}) => {
                return <option key={id} value={id} data-name={name}>{name}</option>
            });
    };

    const getTalentOptions = () => {
        return getTalentData()
            .map(({id, name}) => {
                return <option key={id} value={id} data-name={name}>{name}</option>
            });
    };


    const payload = {
        originSchool: enteredData.originSchool.id || '',
        targetSchoolType: enteredData.targetSchoolType.id || '',
        targetSchoolCity: enteredData.targetSchoolCity.id || '',
        targetSchool: enteredData.targetSchool.id || '',
        specification: enteredData.specification.id || '',
        talent: enteredData.talent.id || ''
    };

    const validationSchema = object({
        originSchool: string()
            .required('Aktuelle Schule ist ein Pflichtfeld')
            .oneOf([
                ...data.map(({id}) => id + ''),
                null
            ], 'Invalide aktuelle Schule'),
        targetSchoolCity: string()
            .required('Ort der Schule ist ein Pflichtfeld')
            .oneOf([
                ...getTargetSchoolCityData().map(({id}) => id + ''),
                null
            ], 'Invalider Ort der Schule'),
        targetSchoolType: string()
            .required('Ausbildungsziel ist ein Pflichtfeld')
            .oneOf([
                ...getTargetSchoolTypeData().map(({id}) => id + ''),
                null
            ], 'Invalides Ausbildungsziel')
    });

    const formik = useFormik({
        initialValues: payload,
        validationSchema: validationSchema,
        validate: ({specification}) => {
            // for some reason cannot trigger specification and talent errors otherwise.
            const errors = {};
            const availableSpecifications = getSpecificationData().map(({id}) => id + '');

            if (availableSpecifications.length) {
                if (!specification) {
                    errors.specification = 'Ausprägung ist ein Pflichtfeld';
                } else if (
                    !availableSpecifications
                        .find(availableSpecification => (
                            availableSpecification === specification
                        ))
                ) {
                    errors.specification = 'Invalide Ausprägung';
                }
            }

            return errors;
        },
        onSubmit: () => {
            return send('SUBMIT');
        }
    });

    return (
        <div className={'grid-container grid-y'}>
            <div
                className={[
                    'small-auto',
                    'large-padding-top_bottom',
                    'medium-padding-top_bottom',
                    'small-padding-top_bottom',
                    'content-container'
                ].join(' ')}>

                <div className={'margin-bottom-3'}>
                    <h1>
                        <strong>Wahl des Schultyps und der Zielschule: {userName}</strong>
                    </h1>
                </div>

                <div className={'grid-x grid-margin-x'}>
                    <div className={'grid-y cell auto'}>
                        <form onSubmit={formik.handleSubmit}>
                            <div>
                                <div>

                                    {errorCodes &&
                                        <div className={'errors'}>
                                            {t(Array.isArray(errorCodes) ? errorCodes[0] : errorCodes)}
                                        </div>
                                    }

                                    {enabledModules.originSchool &&
                                        <FormGuide html={formText[0] ? formText[0] : ''}>
                                            <label htmlFor={'originSchool'}>{t('schoolLevel')} *</label>
                                            {formik.errors.originSchool && formik.touched.originSchool ?
                                                <div className={'errors'}>{formik.errors.originSchool}</div>
                                                : null}
                                            <span className={'select-wrapper'}>
                                                <select
                                                    name={'originSchool'}
                                                    className={
                                                        formik.errors.originSchool &&
                                                        formik.touched.originSchool ?
                                                            'error' :
                                                            ''
                                                    }
                                                    onChange={event => {
                                                        const data = {
                                                            id: event.target.value,
                                                            name: event.target.selectedOptions[0].dataset.name
                                                        };

                                                        formik.values.targetSchoolType = '';
                                                        formik.values.targetSchoolCity = '';
                                                        formik.values.specification = '';
                                                        formik.values.talent = '';

                                                        send({type: 'ORIGIN_SCHOOL_CHANGE', value: data});
                                                        formik.handleChange(event);
                                                        formik.setFieldTouched(
                                                            'originSchool',
                                                            true,
                                                            false
                                                        );
                                                    }}
                                                    value={formik.values.originSchool}
                                                >
                                                    <option value={''} disabled={true}>
                                                        {t('optionsDefaultValue')}
                                                    </option>
                                                    {getOriginSchoolOptions()}
                                                </select>
                                            </span>
                                        </FormGuide>
                                    }
                                    {enabledModules.targetSchoolType &&
                                        <FormGuide html={formText[1] ? formText[1] : ''}>
                                            <label htmlFor={'targetSchoolType'}>
                                                {t('registrationForSchoolType')} *
                                            </label>
                                            {formik.errors.targetSchoolType && formik.touched.targetSchoolType ?
                                                <div className={'errors'}>{formik.errors.targetSchoolType}</div>
                                                : null}
                                            <span className={'select-wrapper'}>
                                                <select
                                                    name={'targetSchoolType'}
                                                    className={
                                                        formik.errors.targetSchoolType &&
                                                            formik.touched.targetSchoolType ?
                                                            'error' :
                                                            ''
                                                    }
                                                    onChange={event => {
                                                        formik.handleChange(event);
                                                        formik.setFieldTouched('targetSchoolType', true, false);
                                                        const data = {
                                                            id: event.target.value,
                                                            name: event.target.selectedOptions[0].dataset.name
                                                        };

                                                        formik.values.targetSchoolCity = '';
                                                        formik.values.specification = '';
                                                        formik.values.talent = '';

                                                        send({type: 'TARGET_SCHOOL_TYPE_CHANGE', value: data});
                                                    }}
                                                    value={formik.values.targetSchoolType}
                                                >
                                                    <option value={''} disabled={true}>
                                                        {t('optionsDefaultValue')}
                                                    </option>
                                                    {getTargetSchoolTypeOptions()}
                                                </select>
                                            </span>
                                        </FormGuide>
                                    }
                                    {enabledModules.targetSchoolCity &&
                                        <FormGuide html={formText[2] ? formText[2] : ''}>
                                            <label htmlFor={'targetSchoolCity'}>
                                                {t('registrationAtLocation')} *
                                            </label>
                                            {formik.errors.targetSchoolCity && formik.touched.targetSchoolCity ?
                                                <div className={'errors'}>{formik.errors.targetSchoolCity}</div>
                                                : null}
                                            <span className={'select-wrapper'}>
                                                <select
                                                    name={'targetSchoolCity'}
                                                    className={
                                                        formik.errors.targetSchoolCity &&
                                                            formik.touched.targetSchoolCity ?
                                                            'error' :
                                                            ''
                                                    }
                                                    onChange={event => {
                                                        formik.handleChange(event);
                                                        formik.setFieldTouched(
                                                            'targetSchoolCity',
                                                            true,
                                                            false
                                                        );
                                                        const data = {
                                                            id:
                                                                event.target.value,
                                                            name:
                                                                event.target.selectedOptions[0].dataset.name,
                                                            targetSchoolId:
                                                                event.target.selectedOptions[0].dataset.targetSchoolId
                                                        };

                                                        formik.values.specification = '';
                                                        formik.values.talent = '';

                                                        send({type: 'TARGET_SCHOOL_CITY_CHANGE', value: data});
                                                    }}
                                                    value={formik.values.targetSchoolCity}
                                                >
                                                    <option value={''} disabled={true}>
                                                        {t('optionsDefaultValue')}
                                                    </option>
                                                    {getTargetSchoolCityOptions()}
                                                </select>
                                            </span>
                                        </FormGuide>
                                    }
                                    {enabledModules.specification && getSpecificationData().length !== 0 &&
                                        <FormGuide html={formText[3] ? formText[3] : ''}>
                                            <label htmlFor={'specification'}>{t('specification')} *</label>
                                            {formik.errors.specification ?
                                                <div className={'errors'}>{formik.errors.specification}</div>
                                                : null}
                                            <span className={'select-wrapper'}>
                                                <select
                                                    name={'specification'}
                                                    className={formik.errors.specification ? 'error' : ''}
                                                    onChange={event => {
                                                        formik.handleChange(event);
                                                        const data = {
                                                            id: event.target.value,
                                                            name: event.target.selectedOptions[0].dataset.name
                                                        };

                                                        send({type: 'SPECIFICATION_CHANGE', value: data});
                                                    }}
                                                    value={formik.values.specification}
                                                >
                                                    <option value={''} disabled={true}>
                                                        {t('optionsDefaultValue')}
                                                    </option>
                                                    {getSpecificationOptions()}
                                                </select>
                                            </span>
                                        </FormGuide>
                                    }
                                    {enabledModules.talent && getTalentData().length !== 0 &&
                                        <FormGuide html={formText[4] ? formText[4] : ''}>
                                            <label htmlFor={'talent'}>{t('talents')}</label>
                                            {formik.errors.talent ?
                                                <div className={'errors'}>{formik.errors.talent}</div>
                                                : null}
                                            <span className={'select-wrapper'}>
                                                <select
                                                    name={'talent'}
                                                    className={formik.errors.talent ? 'error' : ''}
                                                    onChange={event => {
                                                        formik.handleChange(event);
                                                        const data = {
                                                            id: event.target.value,
                                                            name: event.target.selectedOptions[0].dataset.name
                                                        };
                                                        send({type: 'TALENT_CHANGE', value: data});
                                                    }}
                                                    value={formik.values.talent}
                                                >
                                                    <option value={''} disabled={false}>
                                                        {t('optionsDefaultValue')}
                                                    </option>
                                                    {getTalentOptions()}
                                                </select>
                                            </span>
                                        </FormGuide>
                                    }
                                </div>
                                <button
                                    type={'submit'}
                                    className={'submit button primary margin-top-2'}
                                >
                                    {t('continue')}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
};

SchoolGoalForm.propTypes = {
    schoolGoalMachine: PropTypes.any
}
