'use strict';

import React, {useState}      from 'react';
import PropTypes              from 'prop-types';
import {BiLinkExternal}       from 'react-icons/bi';
import {AiFillQuestionCircle} from 'react-icons/ai';
import { GrClose }            from 'react-icons/gr'

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const InfoGuide = () => {

    const [modalOpen, setModalOpen] = useState(false);

    const link =
        // eslint-disable-next-line
        'https://mittelschulen.tg.ch/public/upload/assets/135612/230629%20AMH_Broschu%CC%88re_Wegweiser_A4_23_24.pdf?fp=3';

    return (
        <>
            <div className={'info-guide'}>
                <button
                    type={'button'}
                    className={'settings-btn'}
                    onClick={() => setModalOpen(true)}
                >
                    <AiFillQuestionCircle className={'icon-info'}/>
                    Info
                </button>
            </div>
            <div className={'info-guide-big'}>
                <div
                    type={'button'}
                    className={'settings-btn'}
                >
                    <AiFillQuestionCircle className={'icon-info'}/>
                    Detaillierte Informationen bietet die Broschüre:
                    <a href={link} target={'new'}>
                        Wegweiser Aufnahmeprüfung 2024
                    </a>
                    <BiLinkExternal/>
                </div>
            </div>

            {
                modalOpen &&
                <div className={'info-modal'}>
                    <GrClose className={'close-modal'} onClick={() => setModalOpen(false)}/>
                    <div className={'modal-content'}>
                        <p>Detailierte Informationen bietet die Broschüre:</p>
                        <a href={link} target={'new'}>Wegweiser Aufnahmeprüfung 2023</a>
                        <BiLinkExternal/>
                    </div>
                </div>
            }

            {
                modalOpen &&
                <div id='modal-backdrop'></div>
            }
        </>
    );
};

InfoGuide.propTypes = {
    authService: PropTypes.any
};
